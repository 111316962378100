import { FC, ReactNode, useContext, useEffect, useRef, useState } from 'react';
import { Footer } from '@BICE_Vida/tsp-components';
import Header, { HeaderLanding } from '@components/Header';
import { BannerT } from '@csi-monorepo/core/domain/insurance';
import Banner from '@src/components/Banner';
import FooterBroker from '@src/components/FooterBroker';
import FullModalProvider from '@src/components/Header/FullModalContext';
import { StepFooterButtonI } from '@src/components/StepFooter/StepFooter';
import { ChatbotContext } from '@src/contexts/chatbotContext';
import useDevice from '@src/hooks/screen/useDevice';
import useAidedSellingStore from '@src/stores/useAidedSellingStore';
import useAppStore from '@src/stores/useAppStore';
import useSponsorStore from '@src/stores/useSponsorStore';
import NewQuotationStepper from '@src/templates/NewQuotation/NewQuotationStepper';
import dynamic from 'next/dynamic';

import styles from './layout.module.scss';

const ChatbotButton = dynamic(() => import('@src/components/ChatbotButton/ChatbotButton'));

type LayoutProps = {
  isLanding?: boolean;
  showBanner?: boolean;
  children: ReactNode;
  scrollable?: () => void;
  withStepper?: boolean;
  continueBtn?: StepFooterButtonI;
  backBtn?: StepFooterButtonI;
  hiddeLogo?: boolean;
};

const Layout: FC<LayoutProps> = ({
  children,
  isLanding = false,
  showBanner = false,
  scrollable,
  withStepper = false,
  continueBtn,
  hiddeLogo = false,
  backBtn,
}) => {
  const { insurance } = useAppStore();
  const { sponsor } = useSponsorStore();
  const { rut, name, isAided, isAidedByUrl } = useAidedSellingStore();
  const [bannerLanding, setBannerLanding] = useState<BannerT>();
  const [bannerFlow, setBannerFlow] = useState<BannerT>();
  const [renderHeader, setRenderHeader] = useState(false);
  const { maxMediaSmall: isMobile } = useDevice();
  const { showChatbot } = useContext(ChatbotContext);
  const ref = useRef<HTMLDivElement>(null);
  const [bannerHeight, setBannerHeight] = useState(0);

  useEffect(() => {
    if (insurance) {
      const currentBanner = insurance?.banner?.find(({ name }) => name === 'landing');
      setBannerLanding(currentBanner);
      const currentFlowBanner = insurance?.banner?.find(({ name }) => name === 'flow');
      setBannerFlow(currentFlowBanner);
    }
  }, [insurance]);

  useEffect(() => {
    setRenderHeader(true);
  }, []);

  useEffect(() => {
    if (ref?.current) {
      setBannerHeight(ref?.current?.clientHeight);
    }
  }, [ref?.current]);

  if (!renderHeader) return null;

  return (
    <>
      <FullModalProvider>
        {!isLanding && showBanner && bannerFlow && (
          <Banner {...bannerFlow} isLanding={false} reactRef={ref} />
        )}
        {isLanding ? (
          <HeaderLanding />
        ) : (
          <div style={{ paddingTop: !isMobile ? bannerHeight : 0 }}>
            <Header className={sponsor ? styles.bv_logo_section : ''} hiddeLogo={hiddeLogo} />
          </div>
        )}
        <div className={isLanding ? styles.containerLayout : undefined}>
          {bannerLanding && showBanner && isLanding && (
            <Banner
              {...bannerLanding}
              scrollable={scrollable}
              isLanding={isLanding}
              reactRef={ref}
            />
          )}
          {bannerLanding && !isMobile && showBanner && <div style={{ height: bannerHeight }}></div>}
          {children}
          {withStepper ? (
            <NewQuotationStepper
              nameAgent={name}
              isAided={isAided}
              isAidedByUrl={isAidedByUrl}
              rutAgent={rut}
              continueBtn={continueBtn}
              backBtn={backBtn}
            />
          ) : (
            <>
              <FooterBroker
                sponsor={sponsor}
                nameAgent={name}
                isAided={isAided}
                isAidedByUrl={isAidedByUrl}
                rutAgent={rut}
              />
              <div className={sponsor || isAided ? styles.footer_aidedSelling : ''}>
                <Footer networkContainerClassName="flex" />
              </div>
            </>
          )}
        </div>
        {showChatbot && <ChatbotButton />}
      </FullModalProvider>
    </>
  );
};

export default Layout;
