import { DependantsT, DependantT } from '@csi-monorepo/core/domain/lead';
import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

const initDependant: DependantT = {
  rut: '',
  name: '',
  lastName: '',
  motherLastName: '',
  birthdate: '',
  relationship: undefined,
  healthSystem: {
    id: undefined,
    name: '',
  },
  diseases: [],
};

type DependantsActions = {
  setDependant: (index: number, value: Partial<DependantT>) => void;
  addDependant: () => void;
  removeDependant: (index: number) => void;
  clearDependants: () => void;
  updateQuantity: (quantity: number) => void;
  updateDependantsQuantity: (quantity: number) => void;
  findByRut: (rut: string) => undefined | DependantT;
  resetStore: () => void;
  setWithDependants: (value: boolean) => void;
};

type DependatsState = DependantsT & DependantsActions;

const initialState: DependantsT = {
  quantity: 0,
  dependants: [],
  withDependants: false,
};

const useDependantsStore = create<DependatsState>()(
  devtools(
    persist(
      (set, get) => ({
        ...initialState,
        setWithDependants: (value: boolean) =>
          set((state) => ({ ...state, withDependants: value })),
        setDependant: (index: number, values: Partial<DependantT>) =>
          set((state) => {
            state.dependants[index] = {
              ...state.dependants[index],
              ...values,
            };
            return { dependants: state.dependants };
          }),
        addDependant: () =>
          set((state) => {
            state.dependants.push({ ...initDependant });
            state.updateQuantity(state.dependants.length);

            return { dependants: state.dependants };
          }),
        removeDependant: (index: number) =>
          set((state) => {
            const auxDependants = [...state.dependants];

            auxDependants.splice(index, 1);
            state.updateQuantity(auxDependants.length);

            return { dependants: auxDependants };
          }),
        clearDependants: () => set(() => ({ dependants: [...initialState.dependants] })),
        updateQuantity: (quantity: number) =>
          set((state) => {
            if (quantity > 4 || quantity < 0) return { quantity: state.quantity };
            return { quantity };
          }),
        updateDependantsQuantity: (quantity: number) => {
          const dependantsQuantity: number = get().dependants.length;
          for (let i = 0; i < Math.abs(dependantsQuantity - quantity); i++) {
            if (dependantsQuantity < quantity && quantity <= 4) {
              set({ dependants: [...get().dependants, initDependant] });
            } else if (dependantsQuantity > quantity && quantity >= 1) {
              set((state) => {
                if (get().dependants.length > 0) state.dependants.pop();
                return { dependants: [...state.dependants] };
              });
            }
          }
        },
        findByRut: (rut: string) => {
          return get().dependants.find((dependant: DependantT) => dependant.rut === rut);
        },
        resetStore: () => set({ ...initialState }),
      }),
      {
        name: 'csi-dependants-storage',
        storage: createJSONStorage(() => sessionStorage),
      }
    )
  )
);

export default useDependantsStore;
