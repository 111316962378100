import { StepFooterPropsI } from '@BICE_Vida/tsp-components';
import {
  CHECKOUT,
  CONTACTINFO,
  DEPENDANTSINFO,
  HEALTHDECLARATION,
  LANDING,
  NEW_QUOTATION,
  PERSONALINFO,
  QUOTATION_FULL,
  REFUNDINFO,
  SUCCESSPAYMENT,
} from '@constants/urls';
import { CheckoutStepPropsT, StepsPropsT, StepT } from '@src/types/steps';
import i18next from 'i18next';

const totalSteps = (skippedSteps: number | undefined) => (skippedSteps ? 9 - skippedSteps : 9);

const purchaseSteps: Record<string, StepT> = {
  /** TOTP Verification */
  // '/verificacion-identidad': {
  //   continueUrl: PERSONALINFO,
  //   backUrl: PERSONALINFO,
  //   stepFooterProps({
  //     isDisabled,
  //     isLoading,
  //     continueOnClick,
  //     backOnClick,
  //     skippedSteps,
  //   }: StepsPropsT): StepFooterPropsI {
  //     return {
  //       flow: {
  //         currentStep: 1,
  //         wasIncreased: false,
  //         steps: 7,
  //         missingStepsText: i18next.t('STEP_FOOTER_MISSING_STEPS', {
  //           count: skippedSteps ? 6 - skippedSteps : 6,
  //         }),
  //       },
  //       continueBtn: {
  //         isLoading,
  //         text: i18next.t('STEP_FOOTER_TEXT_BUTTON_CONTINUE'),
  //         onClick: () => {
  //           if (continueOnClick) continueOnClick(this.continueUrl);
  //         },
  //         isDisabled,
  //       },
  //       backBtn: {
  //         text: i18next.t('STEP_FOOTER_TEXT_BUTTON_BACK'),
  //         onClick: () => {
  //           if (backOnClick) {
  //             if (backOnClick) backOnClick(this.backUrl);
  //           }
  //         },
  //       },
  //     };
  //   },
  // },
  /** plan-select  **/
  [QUOTATION_FULL]: {
    continueUrl: NEW_QUOTATION,
    backUrl: LANDING,
    first: true,
    stepFooterProps({
      isDisabled,
      isLoading,
      continueOnClick,
      backOnClick,
      skippedSteps,
    }: StepsPropsT): StepFooterPropsI {
      return {
        flow: {
          currentStep: 1,
          wasIncreased: false,
          steps: totalSteps(skippedSteps),
          missingStepsText: i18next.t('STEP_FOOTER_MISSING_STEPS', {
            count: skippedSteps ? 8 - skippedSteps : 8,
          }),
        },
        continueBtn: {
          isLoading,
          text: i18next.t('STEP_FOOTER_TEXT_BUTTON_CONTINUE'),
          onClick: () => {
            if (continueOnClick) continueOnClick(this.continueUrl);
          },
          isDisabled,
        },
        backBtn: {
          text: i18next.t('STEP_FOOTER_TEXT_BUTTON_BACK'),
          onClick: () => {
            if (backOnClick) backOnClick(this.backUrl);
          },
        },
      };
    },
  },
  /** Quotation-info  **/
  [NEW_QUOTATION]: {
    continueUrl: PERSONALINFO,
    backUrl: QUOTATION_FULL,
    stepFooterProps({
      isDisabled,
      isLoading,
      continueOnClick,
      backOnClick,
      skippedSteps,
    }: StepsPropsT): StepFooterPropsI {
      return {
        flow: {
          currentStep: 2,
          wasIncreased: false,
          steps: totalSteps(skippedSteps),
          missingStepsText: i18next.t('STEP_FOOTER_MISSING_STEPS', {
            count: skippedSteps ? 7 - skippedSteps : 7,
          }),
        },
        continueBtn: {
          isLoading,
          text: i18next.t('STEP_FOOTER_TEXT_BUTTON_CONTINUE'),
          onClick: () => {
            if (continueOnClick) continueOnClick(this.continueUrl);
          },
          isDisabled,
        },
        backBtn: {
          text: i18next.t('STEP_FOOTER_TEXT_BUTTON_BACK'),
          onClick: () => {
            if (backOnClick) backOnClick(this.backUrl);
          },
        },
      };
    },
  },
  /** Personal-info */
  [PERSONALINFO]: {
    continueUrl: CONTACTINFO,
    backUrl: NEW_QUOTATION,
    stepFooterProps({
      isDisabled,
      isLoading,
      continueOnClick,
      backOnClick,
      skippedSteps,
    }: StepsPropsT): StepFooterPropsI {
      return {
        flow: {
          currentStep: 3,
          wasIncreased: false,
          steps: totalSteps(skippedSteps),
          missingStepsText: i18next.t('STEP_FOOTER_MISSING_STEPS', {
            count: skippedSteps ? 6 - skippedSteps : 6,
          }),
        },
        continueBtn: {
          isLoading,
          text: i18next.t('STEP_FOOTER_TEXT_BUTTON_CONTINUE'),
          onClick: () => {
            if (continueOnClick) continueOnClick(this.continueUrl);
          },
          isDisabled,
        },
        backBtn: {
          text: i18next.t('STEP_FOOTER_TEXT_BUTTON_BACK'),
          onClick: () => {
            if (backOnClick) backOnClick(this.backUrl);
          },
        },
      };
    },
  },
  /** Contact-info */
  [CONTACTINFO]: {
    continueUrl: DEPENDANTSINFO,
    backUrl: PERSONALINFO,
    stepFooterProps({
      isDisabled,
      isLoading,
      continueOnClick,
      backOnClick,
      skippedSteps,
    }: StepsPropsT): StepFooterPropsI {
      return {
        flow: {
          currentStep: 4,
          wasIncreased: false,
          steps: totalSteps(skippedSteps),
          missingStepsText: i18next.t('STEP_FOOTER_MISSING_STEPS', {
            count: skippedSteps ? 5 - skippedSteps : 5,
          }),
        },
        continueBtn: {
          isLoading,
          text: i18next.t('STEP_FOOTER_TEXT_BUTTON_CONTINUE'),
          onClick: () => {
            if (continueOnClick) continueOnClick(this.continueUrl);
          },
          isDisabled,
        },
        backBtn: {
          text: i18next.t('STEP_FOOTER_TEXT_BUTTON_BACK'),
          onClick: () => {
            if (backOnClick) backOnClick(this.backUrl);
          },
        },
      };
    },
  },
  /** Dependants-info */
  [DEPENDANTSINFO]: {
    continueUrl: REFUNDINFO,
    backUrl: CONTACTINFO,
    optional: true,
    stepFooterProps({
      isDisabled,
      isLoading,
      continueOnClick,
      backOnClick,
    }: StepsPropsT): StepFooterPropsI {
      return {
        flow: {
          currentStep: 5,
          wasIncreased: true,
          steps: 9,
          missingStepsText: i18next.t('STEP_FOOTER_MISSING_STEPS', {
            count: 4,
          }),
        },
        continueBtn: {
          text: i18next.t('STEP_FOOTER_TEXT_BUTTON_CONTINUE'),
          onClick: () => {
            if (continueOnClick) continueOnClick(this.continueUrl);
          },
          isDisabled,
          isLoading,
        },
        backBtn: {
          text: i18next.t('STEP_FOOTER_TEXT_BUTTON_BACK'),
          onClick: () => {
            if (backOnClick) backOnClick(this.backUrl);
          },
        },
      };
    },
  },
  /** Refund-info */
  [REFUNDINFO]: {
    continueUrl: HEALTHDECLARATION,
    backUrl: DEPENDANTSINFO,
    stepFooterProps({
      isDisabled,
      isLoading,
      continueOnClick,
      backOnClick,
      skippedSteps,
    }: StepsPropsT): StepFooterPropsI {
      return {
        flow: {
          currentStep: skippedSteps ? 6 - skippedSteps : 6,
          wasIncreased: true,
          steps: totalSteps(skippedSteps),
          missingStepsText: i18next.t('STEP_FOOTER_MISSING_STEPS', {
            count: 3,
          }),
        },
        continueBtn: {
          isDisabled,
          isLoading,
          text: i18next.t('STEP_FOOTER_TEXT_BUTTON_CONTINUE'),
          onClick: () => {
            if (continueOnClick) continueOnClick(this.continueUrl);
          },
        },
        backBtn: {
          text: i18next.t('STEP_FOOTER_TEXT_BUTTON_BACK'),
          onClick: () => {
            if (backOnClick) backOnClick(this.backUrl);
          },
        },
      };
    },
  },
  /** Health-declaration */
  [HEALTHDECLARATION]: {
    continueUrl: CHECKOUT,
    backUrl: REFUNDINFO,
    stepFooterProps({
      isDisabled,
      isLoading,
      continueOnClick,
      backOnClick,
      skippedSteps,
    }: StepsPropsT): StepFooterPropsI {
      return {
        flow: {
          currentStep: skippedSteps ? 7 - skippedSteps : 7,
          wasIncreased: true,
          steps: totalSteps(skippedSteps),
          missingStepsText: i18next.t('STEP_FOOTER_MISSING_STEPS', {
            count: 2,
          }),
        },
        continueBtn: {
          isDisabled,
          isLoading,
          text: i18next.t('STEP_FOOTER_TEXT_BUTTON_CONTINUE'),
          onClick: () => {
            if (continueOnClick) continueOnClick(this.continueUrl);
          },
        },
        backBtn: {
          text: i18next.t('STEP_FOOTER_TEXT_BUTTON_BACK'),
          onClick: () => {
            if (backOnClick) backOnClick(this.backUrl);
          },
        },
      };
    },
  },
  /** Checkout */
  [CHECKOUT]: {
    continueUrl: SUCCESSPAYMENT,
    backUrl: HEALTHDECLARATION,
    stepFooterProps({
      isDisabled,
      isLoading,
      price,
      continueOnClick,
      backOnClick,
      skippedSteps,
    }: CheckoutStepPropsT): StepFooterPropsI {
      return {
        flow: {
          currentStep: skippedSteps ? 8 - skippedSteps : 8,
          wasIncreased: true,
          steps: totalSteps(skippedSteps),
          missingStepsText: i18next.t('STEP_FOOTER_MISSING_STEPS', {
            count: 1,
          }),
        },
        continueBtn: {
          isDisabled,
          isLoading,
          text: i18next.t('STEP_FOOTER_CHECKOUT_PAY_BUTTON', { PRICE: price }),
          onClick: () => {
            if (continueOnClick) continueOnClick(this.continueUrl);
          },
        },
        backBtn: {
          text: i18next.t('STEP_FOOTER_TEXT_BUTTON_BACK'),
          onClick: () => {
            if (backOnClick) backOnClick(this.backUrl);
          },
        },
      };
    },
  },
  /** pago-exito-error */
  [SUCCESSPAYMENT]: {
    continueUrl: LANDING,
    backUrl: CHECKOUT,
    last: true,
    stepFooterProps({
      isDisabled,
      isLoading,
      price,
      continueOnClick,
      backOnClick,
    }: CheckoutStepPropsT): StepFooterPropsI {
      return {
        flow: {
          currentStep: 9,
          wasIncreased: true,
          steps: 9,
          missingStepsText: i18next.t('STEP_FOOTER_MISSING_STEPS', {
            count: 0,
          }),
        },
        continueBtn: {
          isDisabled,
          isLoading,
          text: i18next.t('STEP_FOOTER_CHECKOUT_PAY_BUTTON', { PRICE: price }),
          onClick: () => {
            if (continueOnClick) continueOnClick(this.continueUrl);
          },
        },
        backBtn: {
          text: i18next.t('STEP_FOOTER_TEXT_BUTTON_BACK'),
          onClick: () => {
            if (backOnClick) backOnClick(this.backUrl);
          },
        },
      };
    },
  },
};

export default purchaseSteps;
