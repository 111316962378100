import React, { RefObject, useContext, useEffect, useRef } from 'react';
import { Text } from '@BICE_Vida/components';
import useDevice from '@src/hooks/screen/useDevice';
import { ClickHeaderPageEvent } from '@src/utils/dataLayer/header';
import Link from 'next/link';
import { useRouter } from 'next/router';

import { HeaderContext } from './ContextHeader';

import styles from './Header.module.scss';

export enum SectionButtonTypeEnum {
  Scroll,
  Page,
  Modal,
}

export type SectionT = {
  id: string;
  name: string;
  type: SectionButtonTypeEnum;
  url?: string;
  open?: () => void;
};

type SectionButtonPropT = {
  text: string;
  index: number;
  section: SectionT;
  listRef?: RefObject<HTMLDivElement>;
};

const SectionButton: React.FC<SectionButtonPropT> = ({ text, index, section, listRef }) => {
  const { selected, handleScroll } = useContext(HeaderContext);
  const item = useRef<HTMLDivElement>(null);
  const { isDesktop } = useDevice();
  const router = useRouter();

  const isSelected = selected === index;
  const classNameText = isSelected ? styles.textItemSelected : styles.textItem;
  const classNameContainer = isSelected ? styles.itemContainerSelected : styles.itemContainer;

  useEffect(() => {
    if (isSelected && !isDesktop && item.current) {
      const element = item.current;
      const elementPosition = element.getBoundingClientRect().left;
      listRef?.current?.scrollTo({
        behavior: 'smooth',
        left: elementPosition,
      });
    }
  }, [isSelected]);

  const onClickHandler = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();

    ClickHeaderPageEvent(section.name);

    if (section?.type == SectionButtonTypeEnum.Modal) {
      if (section.open) section.open();
      return;
    } else if (
      !isSelected &&
      router.pathname != section?.url &&
      section?.type == SectionButtonTypeEnum.Page
    ) {
      if (section.url) router.push(section.url);
      return;
    }

    handleScroll(section.id, index);
  };
  return (
    <div ref={item}>
      <Link
        href={section.url ?? ''}
        passHref
        onClick={onClickHandler}
        className={classNameContainer}
      >
        <Text className={classNameText}>{text}</Text>
      </Link>
    </div>
  );
};

export default SectionButton;
