import React from 'react';
import useSponsorStore from '@src/stores/useSponsorStore';
import Image from 'next/image';

import styles from './LogoSponsor.module.scss';

const LogoSponsor = ({ divider = true }) => {
  const { sponsor } = useSponsorStore();

  return (
    <div className={styles.logo_container}>
      {divider && <div className={styles.divider} />}
      {sponsor?.logo && (
        <div className={styles.logo}>
          <Image
            alt=""
            src={sponsor.logo.src}
            height={sponsor.logo.header.height}
            width={sponsor.logo.header.width}
            layout="fixed"
          />
        </div>
      )}
    </div>
  );
};

export default LogoSponsor;
