import { StepFooterPropsI } from '@BICE_Vida/tsp-components';
import { CONTACTINFO, DEPENDANTSINFO, LANDING, PERSONALINFO, REFUNDINFO } from '@constants/urls';
import { StepsPropsT, StepT } from '@src/types/steps';
import i18next from 'i18next';

const assistSteps: Record<string, StepT> = {
  /** Personal-info */
  [PERSONALINFO]: {
    continueUrl: CONTACTINFO,
    backUrl: LANDING,
    stepFooterProps({
      isDisabled,
      isLoading,
      continueOnClick,
      backOnClick,
    }: StepsPropsT): StepFooterPropsI {
      return {
        flow: {
          currentStep: 1,
          wasIncreased: false,
          steps: 4,
          missingStepsText: i18next.t('STEP_FOOTER_MISSING_STEPS', {
            count: 3,
          }),
        },
        continueBtn: {
          isLoading,
          text: i18next.t('STEP_FOOTER_TEXT_BUTTON_CONTINUE'),
          onClick: () => {
            if (continueOnClick) continueOnClick(this.continueUrl);
          },
          isDisabled,
        },
        backBtn: {
          text: i18next.t('STEP_FOOTER_TEXT_BUTTON_BACK'),
          onClick: () => {
            if (backOnClick) backOnClick(this.backUrl);
          },
        },
      };
    },
  },
  /** Contact-info */
  [CONTACTINFO]: {
    continueUrl: DEPENDANTSINFO,
    backUrl: PERSONALINFO,
    stepFooterProps({
      isDisabled,
      isLoading,
      continueOnClick,
      backOnClick,
    }: StepsPropsT): StepFooterPropsI {
      return {
        flow: {
          currentStep: 2,
          wasIncreased: true,
          steps: 4,
          missingStepsText: i18next.t('STEP_FOOTER_MISSING_STEPS', {
            count: 2,
          }),
        },
        continueBtn: {
          isLoading,
          text: i18next.t('STEP_FOOTER_TEXT_BUTTON_CONTINUE'),
          onClick: () => {
            if (continueOnClick) continueOnClick(this.continueUrl);
          },
          isDisabled,
        },
        backBtn: {
          text: i18next.t('STEP_FOOTER_TEXT_BUTTON_BACK'),
          onClick: () => {
            if (backOnClick) backOnClick(this.backUrl);
          },
        },
      };
    },
  },
  /** Dependants-info */
  [DEPENDANTSINFO]: {
    continueUrl: REFUNDINFO,
    backUrl: CONTACTINFO,
    stepFooterProps({
      isDisabled,
      isLoading,
      continueOnClick,
      backOnClick,
    }: StepsPropsT): StepFooterPropsI {
      return {
        flow: {
          currentStep: 3,
          wasIncreased: true,
          steps: 4,
          missingStepsText: i18next.t('STEP_FOOTER_MISSING_STEPS', {
            count: 1,
          }),
        },
        continueBtn: {
          text: i18next.t('STEP_FOOTER_TEXT_BUTTON_CONTINUE'),
          onClick: () => {
            if (continueOnClick) continueOnClick(this.continueUrl);
          },
          isDisabled,
          isLoading,
        },
        backBtn: {
          text: i18next.t('STEP_FOOTER_TEXT_BUTTON_BACK'),
          onClick: () => {
            if (backOnClick) backOnClick(this.backUrl);
          },
        },
      };
    },
  },
  /** Refund-info */
  [REFUNDINFO]: {
    continueUrl: '',
    backUrl: DEPENDANTSINFO,
    stepFooterProps({
      isDisabled,
      isLoading,
      continueOnClick,
      backOnClick,
    }: StepsPropsT): StepFooterPropsI {
      return {
        flow: {
          currentStep: 4,
          wasIncreased: true,
          steps: 4,
          missingStepsText: i18next.t('STEP_FOOTER_MISSING_STEPS', {
            count: 0,
          }),
        },
        continueBtn: {
          isDisabled,
          isLoading,
          text: i18next.t('STEP_FOOTER_TEXT_BUTTON_CONTINUE'),
          onClick: () => {
            if (continueOnClick) continueOnClick(this.continueUrl);
          },
        },
        backBtn: {
          text: i18next.t('STEP_FOOTER_TEXT_BUTTON_BACK'),
          onClick: () => {
            if (backOnClick) backOnClick(this.backUrl);
          },
        },
      };
    },
  },
};

export default assistSteps;
