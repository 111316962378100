import React from 'react';

import styles from './CounterCyber.module.scss';

type CounterInputT = {
  value: string | number;
  description: string;
  isSeparator?: boolean;
};

const CounterInput = ({ value, description }: CounterInputT) => {
  return (
    <div className={styles.counter__input}>
      <div className={styles.counter__input__title}>{value}</div>
      <div className={styles.counter__input__text}>{description}</div>
    </div>
  );
};

export default CounterInput;
