const componentsId = {
  landingHeroSection: 'landing_hero_section',
  landingHeroBtnPrecios: 'landing_hero_hiring_button',
  landingBenefitsSection: 'lading_benefits_section',
  landingBenefitsCard: 'landing_benefits_card_',
  landingBenefitsMoreBtn: 'landing_benefits_more_btn',
  landingPlansSection: 'landing_plans_section',
  landingPlansQuoter: 'landing_plans_quoter',
  landingPlansQuoterDependants: 'landing_plans_quoter_dependats',
  landingPlanEstandar: 'landing_plan_estandar',
  landingPlanPremium: 'landing_plan_premium',
  landingPlanEstandarHireBtn: 'landing_plan_estandar_hire_button',
  landingPlanPremiumHireBtn: 'landing_plan_premium_hire_button',
  landingPlanEstandarDetailsBtn: 'landing_plan_estandar_details',
  landingPlanPremiumDetails: 'landing_plan_premium_details',
  landingPlanVersus: 'landing_plan_versus_button',
  landingDocumentsSection: 'landing_documents_section',
  landingDocument: 'landing_document_',
  landingFaqSection: 'landing_faq_section',
  landingFaqQuestion: 'landing_faq_question_',
  landingRefundsSection: 'landing_refunds_section',
  landingPlansAndCoverages: 'landing_plans_and_coverages',

  moreInfoModal: 'modal_more_info_section',
  moreInfoModalResume: 'modal_more_info_resume',
  moreInfoModalDetails: 'modal_more_info_details',
  moreInfoModalCoverage: 'modal_more_info_coverage_',

  formHireBtnBack: 'form_hire_btn_back',
  formHireBtnContinue: 'form_hire_btn_continue',

  generatorUrl: 'generator_url',

  wizardStep: (currentStep: string) => `wizard_step_${currentStep}`,
  personalInfoStep: {
    personalInfoForm: 'form_personal_info',
    formHireInputRut: 'form_hire_input_rut',
    formHireInputName: 'form_hire_input_name',
    formHireInputLastName: 'form_hire_input_lastname',
    formHireInputSecondLastName: 'form_hire_input_second_lastname',
    formHireInputBirthdate: 'form_hire_input_birthdate',
    formHireInputHealthSystem: 'form_hire_input_healthsystem',
    formHireInputTelephone: 'form_hire_input_telephone',
  },
  contactStep: {
    contactInfoForm: 'form_contact_info',
    formHireInputEmail: 'form_hire_input_email',
    formHireInputEmailConfirmation: 'form_hire_input_email_confirmation',
    formHireInputRegion: 'form_hire_input_region',
    formHireInputProvince: 'form_hire_input_province',
    formHireInputAddres: 'form_hire_input_Addres',
    formHireInputHouse: 'form_hire_input_house',
  },
  bankInfoStep: {
    bankInfoForm: 'form_bank_info',
    formHireInputBank: 'form_hire_input_bank',
    formHireInputAccount: 'form_hire_input_account',
    formHireInputAccountNumber: 'form_hire_input_account_number',
    formHireInputRut: 'form_hire_input_rut',
    formHireInputName: 'form_hire_input_name',
  },
  dependantsStep: {
    dependantsInfoForm: 'form_dependants_info_',
    input: 'step_dependant_input',
    addDependantsBtn: 'step_dependat_add_dependant_button',
    lessdependantsIcon: 'step_dependat_less_dependant_icon_',
    formDependantInputRut: 'form_dependant_input_rut_',
    formDependantInputName: 'form_dependant_input_name_',
    formDependantInputLastName: 'form_dependant_input_lastname_',
    formDependantInputSecondLastName: 'form_dependant_input_second_lastname_',
    formDependantInputRelation: 'form_dependant_input_relation_',
    formDependantInputBirthdate: 'form_dependant_input_birthdate_',
    formDependantInputHealthSystem: 'form_dependant_input_healthsystem_',
  },
  healthDeclarationStep: {
    healthDeclarationForm: 'form_health_declaration_info',
    formhealthDeclarationHelpText: 'form_health_declaration_help_text',
    formhealthDeclarationInfoModal: 'form_health_declaration_info_modal',
    formhealthDeclarationInfoWarning: 'form_health_declaration_info_warning',
    formealthDeclarationInfoDisclaimer: 'form_health_declaration_info_disclaimer',
    formhealthDeclarationQuestion: 'form_health_declaration_question',
    formhealthDeclarationQuestionContainer: 'form_health_declaration_question_container',
    formhealthDeclarationResponse: 'form_health_declaration_response_',
    formhealthDeclarationSelectFamily: 'form_health_declaration_select_family',
    formhealthDeclarationInputDesease: 'form_health_declaration_input_desease',
    formhealthDeclarationAddDesease: 'form_health_declaration_add_desease',
    formhealthDeclarationRemoveDesease: 'form_health_declaration_remove_desease',
    formhealthDeclarationTermsConditions: 'form_health_declaration_terms_conditions',
    identityValidationModal: 'identity_validation_modal',
  },

  cardSummaryResume: {
    cardSummaryInfo: 'card_summary_info_resume',
    cardSummarySelectedPlan: 'card_summary_selected_plan',
    cardSummaryPlanPrice: 'card_summary_plan_price',
    cardSummaryPlanPriceProportional: 'card_summary_plan_price_proportional',
  },

  sumary: {
    checkoutSection: 'checkout_section',
    checkoutPaymentMethod: 'checkout_payment_method',
    checkoutTermsConditions: 'checkout_payment_terms_conditions',
    checkoutModal: 'checkout_modal',
    checkoutModalBackButton: 'checkout_modal_back_button',
    checkoutModalContinueButton: 'checkout_modal_continue_button',
  },

  payment: {
    paymentSuccessfulSection: 'successful_payment_section',
    paymentErrorSection: 'error_payment_section',
    paymentSummaryCard: 'payment_summary_card',
    paymentSuccessfulBtn: 'payment_successful_button',
    paymentErrorBtn: 'payment_error_button',
  },

  landingHeroId: {
    index: 0,
    benefits: 1,
    plans: 2,
    refunds: 3,
  },

  benefits: {
    benefitsHero: 'benefits_hero',
    benefitsEmergency: 'benefits_emergency',
    benefitsOnline: 'benefits_online',
    benefitsDental: 'benefits_dental',
    benefitsExclusive: 'benefits_exclusive',
  },

  refunds: {
    refundsHero: 'refunds_hero',
    refundsSteps: 'refunds_steps',
    refundsSign: 'refunds_sign',
  },

  quotation: {
    resumeModal: 'resume_modal',
  },
};

export default componentsId;
