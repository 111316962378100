import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@bice_vida/ds-components-button';
import { VIRTUAL_BRANCH_URL } from '@src/constants/urls';
import useDevice from '@src/hooks/screen/useDevice';
import { ClickVirtualBranchHeaderPageEvent } from '@src/utils/dataLayer/header';

import styles from './Header.module.scss';

const VirtualBranchButton = () => {
  const { t } = useTranslation();
  const { mediaSmall } = useDevice();

  const handleClickVirtualBranch = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    ClickVirtualBranchHeaderPageEvent();
    window.open(VIRTUAL_BRANCH_URL, '_blank');
  };

  return (
    <a
      onClick={handleClickVirtualBranch}
      target="_blank"
      rel="noopener noreferrer"
      className={styles.disabledLink}
      href={VIRTUAL_BRANCH_URL}
    >
      <Button variant="secondary" size={mediaSmall ? 'm' : 's'} className={styles.buttonBranch}>
        {t(!mediaSmall ? 'VIRTUAL_BRANCH' : 'GO_TO_VIRTUAL_BRANCH')}
      </Button>
    </a>
  );
};

export default VirtualBranchButton;
