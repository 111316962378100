export const getAidedAttribution = (
  isAided?: boolean,
  attribution?: string,
  preQuotation?: boolean
): string => {
  if (preQuotation && attribution) {
    return `${isAided ? 'si' : 'no'}-${attribution}`;
  }
  return isAided ? 'si' : 'no';
};
