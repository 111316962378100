import React, { createContext, useEffect, useState } from 'react';
import { BENEFITS, LANDING, REFUNDS } from '@src/constants/urls';
import { useRouter } from 'next/router';

export const HeaderContext = createContext({
  selected: 0,
  isLoading: false,

  setIsLoading: (_: boolean) => {
    return;
  },
  handleSetSelected: (_: number) => {
    return;
  },
  handleScroll: (_: string, __: number) => {
    return;
  },
});

const HeaderProvider = ({ children }: { children: React.ReactNode }) => {
  const [selected, setSelected] = useState<number>(0);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const { pathname, query } = useRouter();

  const handleSetSelected = (index: number) => {
    setSelected(index);
    setIsLoading(true);
  };

  const handleScroll = (section: string, index: number) => {
    const element = document.getElementById(section);
    if (!element) return;
    const headerOffset = 110;
    const elementPosition = element.getBoundingClientRect().top;
    const offsetPosition = elementPosition + window.pageYOffset - headerOffset;

    handleSetSelected(index);
    window.scrollTo({
      top: offsetPosition,
      behavior: 'smooth',
    });
  };

  useEffect(() => {
    if (isLoading) {
      setTimeout(() => {
        setIsLoading(false);
      }, 1000);
      return;
    }
  }, [isLoading]);

  useEffect(() => {
    if (pathname.includes(BENEFITS)) handleSetSelected(1);
    if (pathname.includes(REFUNDS)) handleSetSelected(3);
  }, [pathname]);

  useEffect(() => {
    const { selected: id, section } = query;
    if (id && section && pathname == LANDING) handleScroll(section as string, Number(id));
  }, [query]);

  return (
    <HeaderContext.Provider
      value={{
        handleSetSelected,
        selected,
        isLoading,
        setIsLoading,
        handleScroll,
      }}
    >
      {children}
    </HeaderContext.Provider>
  );
};

export default HeaderProvider;
