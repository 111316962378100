import React from 'react';
import { Button } from '@bice_vida/ds-components-button';
import { Container } from '@BICE_Vida/tsp-components';
import FooterBroker from '@src/components/FooterBroker';
import { StepFooterButtonI } from '@src/components/StepFooter/StepFooter';
import useSponsorStore from '@src/stores/useSponsorStore';

import styles from './NewQuotationTemplate.module.scss';

type NewQuotationStepperPropsT = {
  continueBtn?: StepFooterButtonI;
  backBtn?: StepFooterButtonI;
  nameAgent?: string;
  isAided?: boolean;
  isAidedByUrl?: boolean;
  rutAgent?: string;
};

const NewQuotationStepper: React.FC<NewQuotationStepperPropsT> = ({
  backBtn,
  continueBtn,
  nameAgent,
  isAided,
  isAidedByUrl,
  rutAgent,
}) => {
  const { sponsor } = useSponsorStore();

  return (
    <>
      <Container fluid className={styles.container_border}>
        <div className={styles.button_container}>
          {backBtn && (
            <Button
              id="footer-back-button"
              data-testid="footer-back-button"
              variant="link"
              size="l"
              className={styles.button_back}
              onClick={backBtn.onClick}
            >
              {backBtn.text}
            </Button>
          )}

          {continueBtn && (
            <Button
              id="footer-continue-button"
              data-testid="footer-continue-button"
              size="l"
              disabled={continueBtn.isDisabled}
              onClick={continueBtn.onClick}
              loading={continueBtn.isLoading}
            >
              {continueBtn.text}
            </Button>
          )}
        </div>
      </Container>
      <FooterBroker
        nameAgent={nameAgent}
        isAided={isAided}
        isAidedByUrl={isAidedByUrl}
        rutAgent={rutAgent}
        sponsor={sponsor}
      />
    </>
  );
};

export default NewQuotationStepper;
