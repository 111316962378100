import { Header } from '@BICE_Vida/tsp-components';
import { LANDING } from '@src/constants/urls';
import useSponsorStore from '@src/stores/useSponsorStore';
import classNames from 'classnames';
import { useRouter } from 'next/router';

import LogoSponsor from '../LogoSponsor/LogoSponsor';

import styles from './Header.module.scss';

type CustomHeaderPropsT = {
  className?: string;
  sticky?: boolean;
  hiddeLogo?: boolean;
};

const CustomHeader = ({ className, sticky, hiddeLogo }: CustomHeaderPropsT): JSX.Element => {
  const router = useRouter();
  const { sponsor } = useSponsorStore();

  const headerClassName = classNames(className, {
    [styles.bv_logo_section]: !sponsor?.showLogo,
  });

  const defaults = {
    image: '/svg/logo/bice_vida_logo.svg',
    altImage: 'Bice Vida logo',
    onClickLogo: () => router.push(LANDING),
  };
  if (hiddeLogo) return <div />;
  return (
    <div className={styles.subContainer}>
      <Header
        altImage={defaults.altImage}
        image={defaults.image}
        onClickLogo={defaults.onClickLogo}
        className={sponsor ? headerClassName : className}
        sticky={sticky}
        rightComponent={sponsor?.logo ? <LogoSponsor /> : undefined}
      />
    </div>
  );
};

export default CustomHeader;
