import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import useAppStore from '@src/stores/useAppStore';

import { updateCountdown } from './Count';
import CounterInput from './CounterInput';

import styles from './CounterCyber.module.scss';

const endTimeAll = {
  CYBER: '07/31/2024 00:00 AM',
  'EXTENDED-CYBER': '06/08/2024 00:00 AM',
};

const CounterCyber = () => {
  const { insurance } = useAppStore();
  const insuranceName = insurance.name;
  const endTimeVar = insuranceName?.includes('Post Cyber')
    ? endTimeAll['EXTENDED-CYBER']
    : endTimeAll['CYBER'];
  const endTime = new Date(endTimeVar);
  const { t } = useTranslation();
  const [day, setDay] = useState<string>('');
  const [hour, setHour] = useState<string>('');
  const [minute, setMinute] = useState<string>('');
  const [second, setSecond] = useState<string>('');

  const updateTime = (days: string, hour: string, minutes: string, seconds: string) => {
    setDay(days);
    setHour(hour);
    setMinute(minutes);
    setSecond(seconds);
  };

  useEffect(() => {
    const { REMAINING_DAYS, REMAINING_HOURS, REMAINING_MINUTES, REMAINING_SECONDS } =
      updateCountdown(() => {
        return;
      }, endTime);
    updateTime(REMAINING_DAYS, REMAINING_HOURS, REMAINING_MINUTES, REMAINING_SECONDS);
    const interval = setInterval(() => updateCountdown(updateTime, endTime), 1000);
    return () => clearInterval(interval);
  }, [endTime]);

  if (!endTime) {
    return null;
  }

  return (
    <div className={styles.counter}>
      <p className={styles.counter__text}>
        <Trans t={t} components={{ span: <span /> }} i18nKey="CYBER_DAY_PROMO_TITLE" />
      </p>
      <div className={styles.counter__wrapper}>
        <CounterInput value={day} description={t('CYBER_DAY_PROMO_DAYS')} isSeparator={true} />
        <div className={styles.counter__dot} />
        <CounterInput value={hour} description={t('CYBER_DAY_PROMO_HOURS')} />
        <div className={styles.counter__dot} />
        <CounterInput value={minute} description={t('CYBER_DAY_PROMO_MINUTES')} />
        <div className={styles.counter__dot} />
        <CounterInput value={second} description={t('CYBER_DAY_PROMO_SECONDS')} />
      </div>
    </div>
  );
};

export default CounterCyber;
