import { SponsorDetailT } from '@csi-monorepo/core/domain/lead';
import useDevice from '@src/hooks/screen/useDevice';
import { t } from 'i18next';
import Image from 'next/image';

import styles from './FooterBroker.module.scss';

interface CustomFooterBrokerPropsT {
  id?: string;
  rut?: string;
  name?: string;
  code?: string;
  primary?: string;
  character?: string;
  logo?: string;
  active?: boolean;
}
interface CustomFooterAgentPropsT {
  nameAgent?: string;
  isAided?: boolean;
  rutAgent?: string;
  isAidedByUrl?: boolean;
  sponsor?: SponsorDetailT;
}

const CustomFooterBroker = ({
  name,
  active,
  nameAgent,
  isAidedByUrl,
  sponsor,
}: CustomFooterBrokerPropsT & CustomFooterAgentPropsT): JSX.Element => {
  const { isDesktop } = useDevice();

  return (
    <div>
      {sponsor?.name && sponsor?.footerText && sponsor?.logo?.footer?.show && (
        <div className={styles.footer_broker_container}>
          <p className={styles.footer_broker_texto}>{sponsor?.footerText}</p>
          <div className={styles.footer_logo}>
            <Image
              src={sponsor.logo.src || ''}
              alt=""
              width={sponsor.logo.footer.width}
              height={sponsor.logo.footer.height}
            />
          </div>
        </div>
      )}
      {active && (
        <div className={styles.footer_broker_container}>
          <div className={styles.footer_broker_circle}>
            <Image
              src="/svg/icon/next_week_24px.svg"
              alt=""
              width={13}
              height={12}
              objectPosition="50% 50%"
            />
          </div>
          <p className={styles.footer_broker_texto}>
            {isDesktop && t('AIDED_SELLING_FOOTER_BROKER').toString()} {name}
          </p>
        </div>
      )}
      {isAidedByUrl && (
        <div className={styles.footer_broker_container}>
          <div className={styles.footer_broker_circle}>
            <Image
              src="/svg/icon/agent_footer_icon.svg"
              alt=""
              width={13}
              height={12}
              objectPosition="50% 50%"
            />
          </div>
          <p className={styles.footer_broker_texto}>
            {t('AIDED_SELLING_FOOTER_AGENT').toString()}
            {nameAgent?.replace(',', ' ')}
          </p>
        </div>
      )}
    </div>
  );
};

export default CustomFooterBroker;
