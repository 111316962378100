import { useContext, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@bice_vida/ds-components-button';
import { HEADER_HEIGHT_DESKTOP } from '@BICE_Vida/tsp-components';
import componentsId from '@src/constants/componentsId';
import { BENEFITS, LANDING, QUOTATION_FULL, REFUNDS } from '@src/constants/urls';
import useDevice from '@src/hooks/screen/useDevice';
import useAppStore from '@src/stores/useAppStore';
import useFlowStore, { supportedFlowsEnum } from '@src/stores/useFlowStore';
import useSponsorStore from '@src/stores/useSponsorStore';
import { hiringMenuBtnEvent } from '@src/utils/dataLayer/landing';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';

import { FullModalContext } from './FullModalContext';
import HeaderLogo from './HeaderLogo';
import SectionButton, { SectionButtonTypeEnum } from './SectionButton';
import VirtualBranchButton from './VirtualBranchButton';

import styles from './Header.module.scss';

const ModalDetailsAllPlans = dynamic(() => import('../ModalDetailsAllPlans'));

const HeaderLanding = (): JSX.Element => {
  const { t } = useTranslation();
  const router = useRouter();
  const { insurance } = useAppStore();
  const { isDesktop } = useDevice();
  const headerRef = useRef<HTMLDivElement>(null);
  const listRef = useRef<HTMLDivElement>(null);
  const { sponsor } = useSponsorStore();
  const { setFlowName } = useFlowStore();
  const {
    currentModalTab,
    currentModalSubTab,
    handleCurrentModalTab,
    handleCurrentModalSubTab,
    isOpenModalDetails,
    onCloseModalHandler,
    onOpenModalHandler,
  } = useContext(FullModalContext);

  const sections = [
    {
      id: componentsId.landingHeroSection,
      name: 'Inicio',
      url: LANDING,
      type: SectionButtonTypeEnum.Page,
    },
    {
      id: componentsId.landingBenefitsSection,
      name: 'Beneficios',
      url: BENEFITS,
      type: SectionButtonTypeEnum.Page,
    },
    {
      id: componentsId.landingPlansAndCoverages,
      name: 'Precio y planes',
      type: SectionButtonTypeEnum.Modal,
      open: onOpenModalHandler,
    },
    {
      id: componentsId.landingRefundsSection,
      name: 'Como reembolsar',
      url: REFUNDS,
      type: SectionButtonTypeEnum.Page,
    },
  ];

  const onScroll = () => {
    const header = headerRef.current;
    const currentYPosition = window.pageYOffset;
    const headerHeight: number = header?.offsetHeight || HEADER_HEIGHT_DESKTOP;

    if (currentYPosition > headerHeight) header?.classList.add(styles.scrolled);
    else header?.classList.remove(styles.scrolled);
  };

  useEffect(() => {
    onScroll();
    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  useEffect(() => {
    const planExist = insurance?.plans && insurance?.plans.length > 0;
    if (
      (planExist && !currentModalTab) ||
      (planExist && !insurance.plans.find(({ id }) => currentModalTab == id))
    ) {
      handleCurrentModalTab(insurance.plans[0].id);
    }
  }, [currentModalTab, insurance, sponsor]);

  return (
    <div ref={headerRef} className={styles.container}>
      <HeaderLogo />

      <div ref={listRef} className={styles.containerList}>
        {sections.map((section, i) => (
          <SectionButton
            key={`section-${i}-${section}`}
            section={section}
            index={i}
            text={t(`SECTIONS.${i}`)}
            listRef={listRef}
          />
        ))}

        {isDesktop && <VirtualBranchButton />}
        {isDesktop && (
          <Button
            size="m"
            onClick={() => {
              hiringMenuBtnEvent();
              setFlowName(supportedFlowsEnum.traditional) as string;
              router.push(QUOTATION_FULL);
            }}
          >
            {t('MENU_QUOTATION_BUTTON')}
          </Button>
        )}
      </div>
      {isOpenModalDetails && (
        <ModalDetailsAllPlans
          isOpen={isOpenModalDetails}
          onCloseHandler={onCloseModalHandler}
          currentPlanId={currentModalTab}
          currentSubTab={currentModalSubTab}
          plans={insurance?.plans}
          onChangeTab={(tab: number) => handleCurrentModalTab(tab)}
          onChangeSubTab={(tab: string) => handleCurrentModalSubTab(tab)}
          enableButtons={true}
        />
      )}
    </div>
  );
};

export default HeaderLanding;
