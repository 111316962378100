import React, { createContext, useEffect, useState } from 'react';
import { COVERAGE_TABS } from '@src/constants/planCoverages';
import useAidedSellingStore from '@src/stores/useAidedSellingStore';
import useAppStore from '@src/stores/useAppStore';
import usePlanStore from '@src/stores/usePlanStore';
import useSponsorStore from '@src/stores/useSponsorStore';
import { openCoveragesLandingEvent } from '@src/utils/dataLayer/landing';

export const FullModalContext = createContext({
  currentModalTab: 0,
  currentModalSubTab: '',
  isOpenModalDetails: false,

  handleCurrentModalTab: (_: number) => {
    return;
  },
  handleCurrentModalSubTab: (_: string) => {
    return;
  },
  onCloseModalHandler: () => {
    return;
  },
  onOpenModalHandler: (_?: number, __?: string) => {
    return;
  },
});

const DEFAULT_SUB_TAB = COVERAGE_TABS.coverages;

const FullModalProvider = ({ children }: { children: React.ReactNode }) => {
  const [isOpenModalDetails, setOpenModalDetails] = useState(false);
  const [currentModalTab, setCurrentTab] = useState<number>(0);
  const [currentModalSubTab, setCurrentModalSubTab] = useState<string>('');
  const { userId } = useAppStore();
  const { isAided, executiveSale } = useAidedSellingStore();
  const { name, id } = usePlanStore();
  const { sponsor } = useSponsorStore();

  const handleCurrentModalTab = (tab: number) => {
    sessionStorage.setItem('currentModalTab', JSON.stringify(tab));
    setCurrentTab(tab);
  };

  const handleCurrentModalSubTab = (subTab: string) => {
    sessionStorage.setItem('currentModalSubTab', subTab);
    setCurrentModalSubTab(subTab);
  };

  const onCloseModalHandler = (): void => handleOpenModalDetails(false);

  const onOpenModalHandler = (tab?: number, subTab?: string): void => {
    openCoveragesLandingEvent({
      isAided: isAided || !!executiveSale,
      userId,
      planName: name,
      sponsor: sponsor?.code,
      preQuotation: sponsor?.preQuotation,
    });

    if (tab) handleCurrentModalTab(tab);
    if (subTab) handleCurrentModalSubTab(subTab);
    else if (!subTab) handleCurrentModalSubTab(DEFAULT_SUB_TAB);

    handleOpenModalDetails(true);
  };

  const handleOpenModalDetails = (isOpen: boolean) => {
    setOpenModalDetails(isOpen);
  };

  useEffect(() => {
    const storageTab = sessionStorage.getItem('currentModalTab');
    const currentTab: number | undefined = storageTab && JSON.parse(storageTab);
    if (currentTab) {
      setCurrentTab(currentTab);
    }
  }, []);

  useEffect(() => {
    if (id) handleCurrentModalTab(id);
  }, [id]);

  return (
    <FullModalContext.Provider
      value={{
        currentModalTab,
        handleCurrentModalTab,
        currentModalSubTab,
        handleCurrentModalSubTab,
        isOpenModalDetails,
        onCloseModalHandler,
        onOpenModalHandler,
      }}
    >
      {children}
    </FullModalContext.Provider>
  );
};

export default FullModalProvider;
