import { FC, useState } from 'react';
import { Trans } from 'react-i18next';
import { BannerT } from '@csi-monorepo/core/domain/insurance';
import { cyberBannerViewDetailsBtnEvent } from '@src/utils/dataLayer/landing';
import Image from 'next/image';

import CounterCyber from '../CounterCyber/CounterCyber';
import CyberModal from '../CyberModal';

import styles from './BannerLanding.module.scss';

type BannerProps = BannerT & {
  isLanding?: boolean;
  reactRef: React.RefObject<HTMLDivElement>;
  handleClickInBanner: (event: React.MouseEvent<HTMLDivElement>) => void;
};

const BannerLandingMobile: FC<BannerProps> = ({
  footer,
  icon,
  isLanding = false,
  modal,
  reactRef,
  handleClickInBanner,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => setIsOpen(false);

  return (
    <>
      <div className={styles.bannerMobile} ref={reactRef} onClick={handleClickInBanner}>
        <div className={styles.bannerMobile__content}>
          {icon && (
            <div className={styles.bannerMobile__content__icons}>
              {icon?.mobile?.tag && (
                <Trans
                  components={{
                    span: <span className={styles.bannerMobile__content__icons__tag} />,
                  }}
                >
                  {icon.mobile.tag}
                </Trans>
              )}
              <Image
                src={icon.mobile?.src ?? ''}
                height={icon.mobile?.height ?? 0}
                width={icon.mobile?.width}
                alt=""
              />
            </div>
          )}
          <div className={styles.bannerMobile__content__texts}>
            {/* Contador  */}
            <CounterCyber />
          </div>
        </div>
        {/* text */}
        {footer && (
          <div className={styles.bannerMobile__content__texts__footer}>
            <p>
              <Trans
                components={{
                  span: <span />,
                  a: (
                    <a
                      id="detail-button"
                      onClick={(e) => {
                        e.preventDefault();
                        if (modal) {
                          cyberBannerViewDetailsBtnEvent();
                          setIsOpen(true);
                        }
                      }}
                    />
                  ),
                }}
              >
                {footer}
              </Trans>
            </p>
          </div>
        )}
      </div>
      {modal && (
        <CyberModal
          id={''}
          testId={''}
          isVisible={isOpen}
          onClose={onClose}
          closeIcon="default"
          isLanding={isLanding}
          {...modal}
        />
      )}
    </>
  );
};

export default BannerLandingMobile;
