import { useMediaQuery } from 'react-responsive';

const useDevice = () => {
  const mediaXXXSmall = useMediaQuery({ minWidth: '275px' });
  const mediaXXSmall = useMediaQuery({ minWidth: '375px' });
  const mediaXSmall = useMediaQuery({ minWidth: '425px' });
  const mediaSmall = useMediaQuery({ minWidth: '576px' });
  const mediaMedium = useMediaQuery({ minWidth: '768px' });
  const mediaLarge = useMediaQuery({ minWidth: '992px' });
  const mediaXLarge = useMediaQuery({ minWidth: '1200px' });

  const maxMediaXXXSmall = useMediaQuery({ maxWidth: '275px' });
  const maxMediaXXSmall = useMediaQuery({ maxWidth: '375px' });
  const maxMediaXSmall = useMediaQuery({ maxWidth: '425px' });
  const maxMediaSmall = useMediaQuery({ maxWidth: '576px' });
  const maxMediaMedium = useMediaQuery({ maxWidth: '768px' });
  const maxMediaLarge = useMediaQuery({ maxWidth: '992px' });
  const maxMediaXLarge = useMediaQuery({ maxWidth: '1200px' });

  const isMobile = !mediaMedium;
  const isMobileOrTablet = (mediaXSmall || mediaSmall) && !mediaLarge;
  const isDesktop = mediaLarge;

  return {
    isMobileOrTablet,
    isDesktop,
    isMobile,

    mediaXXXSmall,
    mediaXXSmall,
    mediaXSmall,
    mediaSmall,
    mediaMedium,
    mediaLarge,
    mediaXLarge,

    maxMediaXXXSmall,
    maxMediaXXSmall,
    maxMediaXSmall,
    maxMediaSmall,
    maxMediaMedium,
    maxMediaLarge,
    maxMediaXLarge,
  };
};

export default useDevice;
