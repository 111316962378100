type BaseDataLayerT = { [K: string]: unknown };

const baseDataLayer = ({ event_params, user }: BaseDataLayerT): void => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({
    event: 'vpv',
    event_params,
    user,
  });
};

export default baseDataLayer;
