import { FC } from 'react';
import { BannerT } from '@csi-monorepo/core/domain/insurance';

import BannerFlow from './BannerFlow';
import BannerLanding from './BannerLanding';

type BannerProps = BannerT & {
  isLanding?: boolean;
  reactRef: React.RefObject<HTMLDivElement>;
  scrollable?: () => void;
};

const Banner: FC<BannerProps> = (props) => {
  const { isLanding } = props;
  if (isLanding) return <BannerLanding {...props} />;

  return <BannerFlow {...props} />;
};

export default Banner;
