import baseDataLayer from './baseDataLayer';

const cardSummaryDetailsModalEvent = (
  step: string,
  funnel: string,
  planName: string | undefined,
  quantity: number,
  price: number | undefined,
  id: string,
  userEmail: string
): void =>
  baseDataLayer({
    event_params: {
      page: `/csi/datos/${step}`,
      page_title: `CSI - Funnel - ${funnel}`,
      product: 'CSI',
      action_type: 'click',
      action: 'Click link Ver detalles',
      view_type: 'web',
      product_type: planName,
      asegurados: quantity + 1,
      enfermedad: '',
      prima: price,
      cargas: quantity > 0 ? 'si' : 'no',
      venta_asistida: '',
      rut_agente: '',
    },
    user: { userId: id, userEmail },
  });

const cyberJourneyBannerViewDetailsBtnEvent = (
  page: string,
  planName: string,
  price: number | undefined,
  quantity: number
): void =>
  baseDataLayer({
    event_params: {
      page: `/csi${page}`,
      page_title: 'CSI - Journey',
      product: 'CSI',
      action_type: 'click',
      action: 'Click botón - ver detalles del banner de cyber',
      view_type: 'web',
      product_type: planName,
      asegurados: quantity + 1,
      enfermedad: '',
      prima: price,
      venta_asistida: '',
      rut_agente: '',
    },
    user: { userId: '' },
    userEmail: '',
  });

const stepBackBtnEvent = (
  step: string,
  funnel: string,
  planName: string,
  quantity: number,
  price: number | undefined,
  id: string,
  userEmail: string
): void =>
  baseDataLayer({
    event_params: {
      page: `/csi/datos/${step}`,
      page_title: `CSI - Funnel - ${funnel}`,
      product: 'CSI',
      action_type: 'click',
      action: 'Click botón Volver',
      view_type: 'web',
      product_type: planName,
      asegurados: quantity + 1,
      enfermedad: '',
      prima: price,
      cargas: quantity > 0 ? 'si' : 'no',
      venta_asistida: '',
      rut_agente: '',
    },
    user: { userId: id, userEmail },
  });

export { cardSummaryDetailsModalEvent, cyberJourneyBannerViewDetailsBtnEvent, stepBackBtnEvent };
