import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '@bice_vida/ds-components-button';
import { Header } from '@BICE_Vida/tsp-components';
import { LANDING, QUOTATION_FULL } from '@src/constants/urls';
import useDevice from '@src/hooks/screen/useDevice';
import useFlowStore, { supportedFlowsEnum } from '@src/stores/useFlowStore';
import useSponsorStore from '@src/stores/useSponsorStore';
import { hiringMenuBtnEvent } from '@src/utils/dataLayer/landing';
import { useRouter } from 'next/router';

import LogoSponsor from '../LogoSponsor/LogoSponsor';

import styles from './Header.module.scss';

const HeaderLogo = () => {
  const { t } = useTranslation();
  const { sponsor } = useSponsorStore();
  const { isDesktop } = useDevice();
  const { setFlowName } = useFlowStore();
  const router = useRouter();

  return (
    <div className={styles.subContainer}>
      <Header
        image="/svg/logo/bice_vida_logo.svg"
        altImage="Bice Vida logo"
        onClickLogo={() => router.push(LANDING)}
        rightComponent={
          sponsor?.logo && sponsor?.logo?.header?.show && isDesktop ? <LogoSponsor /> : undefined
        }
      />
      {!isDesktop && (
        <Button
          size="m"
          onClick={() => {
            hiringMenuBtnEvent();
            setFlowName(supportedFlowsEnum.traditional) as string;
            router.push(QUOTATION_FULL);
          }}
          className={styles.quotationButton}
        >
          {t('MENU_QUOTATION_BUTTON')}
        </Button>
      )}
    </div>
  );
};

export default HeaderLogo;
