import { FC, useContext } from 'react';
import { Trans } from 'react-i18next';
import { Text } from '@BICE_Vida/components';
import { Button } from '@bice_vida/ds-components-button';
import { BannerModalT } from '@csi-monorepo/core/domain/insurance';
import componentsId from '@src/constants/componentsId';
import useDevice from '@src/hooks/screen/useDevice';

import BaseModal from '../BaseModal';
import { BaseModalPropT } from '../BaseModal/BaseModal';
import { HeaderContext } from '../Header/ContextHeader';

import styles from './CyberModal.module.scss';

type CyberModalProps = BannerModalT & BaseModalPropT & { isLanding: boolean };

const CyberModal: FC<CyberModalProps> = ({
  id,
  testId,
  isVisible,
  onClose,
  closeIcon,
  image,
  title,
  content,
  closeButtonText,
}) => {
  const { maxMediaSmall: isMobile } = useDevice();
  const { handleScroll } = useContext(HeaderContext);
  return (
    <BaseModal
      id={id}
      testId={testId}
      onClose={onClose}
      fullscreen={false}
      isVisible={isVisible}
      closeIcon={closeIcon}
      className={styles.cyber_modal}
      modalWidth={isMobile ? 328 : 528}
    >
      <div className={styles.cyber_modal__title}>
        {title && (
          <Text tag="h1" fontWeight={700} fontTypo="title-medium" className={styles.title}>
            {title}
          </Text>
        )}
      </div>
      <div className={styles.cyber_modal__content}>
        {content &&
          content?.map((text, index) => (
            <p key={`modal-cyber-content-${index}`}>
              <Trans
                i18nKey={text}
                components={{
                  bold: <strong />,
                  span: <span />,
                  ul: <ul />,
                  li: <li />,
                  a: <a />,
                }}
              />
            </p>
          ))}
      </div>
      {closeButtonText && (
        <div className={styles.cyber_modal__footer}>
          <Button
            size="l"
            onClick={() => {
              onClose();
              setTimeout(() => {
                handleScroll(componentsId.landingPlansSection, 3);
              }, 320);
            }}
          >
            {closeButtonText}
          </Button>
        </div>
      )}
    </BaseModal>
  );
};

export default CyberModal;
