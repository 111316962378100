import assistSteps from '@src/config/steps/assist/assistSteps';
import clientSteps from '@src/config/steps/assist/clientSteps';
import purchaseSteps from '@src/config/steps/purchase';
import { StepT } from '@src/types/steps';
import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';

export enum supportedFlowsEnum {
  traditional = 'traditional',
  assistSteps = 'assistSteps',
  clientSteps = 'clientSteps',
}

const supportedFlows = { traditional: purchaseSteps, assistSteps, clientSteps };

type FlowStoreT = {
  flowName?: supportedFlowsEnum;
  steps: Record<string, boolean>;
};

type FlowStoreActions = {
  setFlowName: (flow: supportedFlowsEnum) => string;
  setFlowNamePrefilled: (flow: supportedFlowsEnum, currentPathname: string) => string;
  setStep: (step: string, value: boolean) => void;
  getCurrentStepIndex: (pathname: string) => number;
  getFirstStepPathname: () => string;
  getCurrentStep: (pathname: string) => StepT;
  getSteps: () => Record<string, StepT>;
  getStep: (pathname: string) => StepT;
  getPrevStep: (pathname: string) => StepT;
  getNextStep: (pathname: string) => StepT;
  getNextStepPathname: (pathname: string) => string;
  getSubsequentStepPathname: (pathname: string) => string;
  getPrevStepPathname: (pathname: string) => string;
  existPathname: (pathname: string) => boolean;
  isFirstStep: (pathname: string) => boolean;
  isLastStep: (pathname: string) => boolean;
  resetStore: () => void;
};

type FlowStoreState = FlowStoreT & FlowStoreActions;

const initialState = {
  flowName: undefined,
  steps: {},
};

const generateEmptySteps = (keys: string[]) => {
  const object: Record<string, boolean> = {};
  for (const key of keys) {
    object[key] = false;
  }

  return object;
};

const generatePrefilledSteps = (flowName: supportedFlowsEnum, currentPathname: string) => {
  const steps: Record<string, boolean> = {};
  const firstPathname: string = getFirstStep(supportedFlows[flowName]);
  const firstStep: StepT = supportedFlows[flowName][firstPathname];

  return prefilledTokenNextSteps(flowName, steps, firstPathname, firstStep, currentPathname);
};

const prefilledTokenNextSteps = (
  flowName: supportedFlowsEnum,
  steps: Record<string, boolean>,
  currentPathname: string,
  currentStep: StepT,
  endPathname: string
): Record<string, boolean> => {
  steps[currentPathname] = true;

  if (currentPathname === endPathname) return steps;

  return prefilledTokenNextSteps(
    flowName,
    steps,
    currentStep?.continueUrl,
    supportedFlows[flowName][currentStep?.continueUrl],
    endPathname
  );
};

const getFirstStep = (steps: Record<string, StepT>): string => {
  for (const step in steps) {
    if (steps[step]?.first) return step;
  }

  return '';
};

const useFlowStore = create<FlowStoreState>()(
  devtools(
    persist(
      (set, get) => ({
        ...initialState,
        setFlowName: (flowName: supportedFlowsEnum) => {
          set({ flowName });
          set({
            steps: generateEmptySteps(
              Object.keys(supportedFlows[get()?.flowName as supportedFlowsEnum])
            ),
          });

          return getFirstStep(supportedFlows[flowName]);
        },
        setFlowNamePrefilled: (flowName: supportedFlowsEnum, currentPathname: string) => {
          set({ flowName });
          set({
            steps: generatePrefilledSteps(flowName, currentPathname),
          });

          return getFirstStep(supportedFlows[flowName]);
        },
        setStep: (step: string, value: boolean) =>
          set((state) => {
            return { steps: { ...state.steps, [step]: value } };
          }),
        getSteps: () => {
          return supportedFlows?.[get()?.flowName as supportedFlowsEnum];
        },
        getStep: (pathname: string) => {
          return supportedFlows?.[get()?.flowName as supportedFlowsEnum]?.[pathname];
        },
        getCurrentStepIndex: (pathname: string) => {
          const keys: string[] = Object.keys(supportedFlows[get()?.flowName as supportedFlowsEnum]);
          return keys?.indexOf(pathname);
        },
        getFirstStepPathname: () => {
          const keys: string[] = Object.keys(supportedFlows[get()?.flowName as supportedFlowsEnum]);
          return keys?.[0];
        },
        getCurrentStep: (pathname: string) => {
          return supportedFlows?.[get()?.flowName as supportedFlowsEnum]?.[pathname];
        },
        getPrevStep: (pathname: string) => {
          const keys: string[] = Object.keys(supportedFlows[get()?.flowName as supportedFlowsEnum]);
          const currentIndex: number = keys.indexOf(pathname);
          const prevStepKey: string = keys[currentIndex - 1];
          return supportedFlows?.[get()?.flowName as supportedFlowsEnum]?.[prevStepKey];
        },
        getNextStep: (pathname: string) => {
          const keys: string[] = Object.keys(supportedFlows[get()?.flowName as supportedFlowsEnum]);
          const currentIndex: number = keys.indexOf(pathname);
          const nextStepKey: string = keys[currentIndex + 1];
          return supportedFlows?.[get()?.flowName as supportedFlowsEnum]?.[nextStepKey];
        },
        getNextStepPathname: (pathname: string) => {
          return supportedFlows?.[get()?.flowName as supportedFlowsEnum][pathname]?.continueUrl;
        },
        getSubsequentStepPathname: (pathname: string) => {
          const nextPathname: string =
            supportedFlows?.[get()?.flowName as supportedFlowsEnum]?.[pathname]?.continueUrl;

          return supportedFlows?.[get()?.flowName as supportedFlowsEnum]?.[nextPathname]
            ?.continueUrl;
        },
        getPrevStepPathname: (pathname: string) => {
          return supportedFlows?.[get()?.flowName as supportedFlowsEnum]?.[pathname]?.backUrl;
        },
        existPathname: (pathname: string) => {
          const keys: string[] = Object.keys(supportedFlows[get()?.flowName as supportedFlowsEnum]);
          const position: number = keys.indexOf(pathname);
          return position >= 0;
        },
        isFirstStep: (pathname: string) => {
          return (
            supportedFlows?.[get()?.flowName as supportedFlowsEnum]?.[pathname]?.first === true
          );
        },
        isLastStep: (pathname: string) => {
          return supportedFlows?.[get()?.flowName as supportedFlowsEnum]?.[pathname]?.last === true;
        },
        resetStore: () => set({ ...initialState }),
      }),
      {
        name: 'csi-flow-storage',
        storage: createJSONStorage(() => sessionStorage),
      }
    )
  )
);

export default useFlowStore;
