import { FC, useState } from 'react';
import { Trans } from 'react-i18next';
import { Button } from '@bice_vida/ds-components-button';
import { BannerT } from '@csi-monorepo/core/domain/insurance';
import useDevice from '@src/hooks/screen/useDevice';
import useDependantsStore from '@src/stores/useDependantsStore';
import usePlanStore from '@src/stores/usePlanStore';
import { cyberJourneyBannerViewDetailsBtnEvent } from '@src/utils/dataLayer/journey';
import Image from 'next/image';
import { useRouter } from 'next/router';

import CyberModal from '../CyberModal';

import styles from './BannerFlow.module.scss';

type BannerProps = BannerT & {
  isLanding?: boolean;
  reactRef: React.RefObject<HTMLDivElement>;
};

const BannerFlow: FC<BannerProps> = (props) => {
  const { text, isLanding = false, modal, reactRef, icon } = props;
  const router = useRouter();
  const { name, planCostUF } = usePlanStore();
  const { quantity } = useDependantsStore();
  const [isOpen, setIsOpen] = useState(false);
  const { maxMediaSmall: isMobile } = useDevice();
  const onClose = () => setIsOpen(false);

  return (
    <>
      <div className={styles.banner} ref={reactRef}>
        <div className={styles.banner__content}>
          {icon && (
            <div className={styles.banner__content__icon}>
              <Image
                src={isMobile ? icon?.mobile?.src ?? '' : icon?.desktop?.src ?? ''}
                width={isMobile ? icon?.mobile?.width : icon?.desktop?.width}
                height={isMobile ? icon?.mobile?.height : icon?.desktop?.height}
                alt=""
              />
            </div>
          )}
          <div className={styles.banner__content__texts}>
            {text && (
              <div className={styles.banner__content__texts}>
                <Trans
                  components={{
                    p: <p />,
                    b: <b />,
                    span: <span />,
                    a: (
                      <Button
                        variant="link"
                        className={styles.banner__content__texts__link}
                        onClick={() => {
                          cyberJourneyBannerViewDetailsBtnEvent(
                            router.pathname,
                            name,
                            planCostUF,
                            quantity
                          );
                          setIsOpen(true);
                        }}
                      >
                        Ver detalles
                      </Button>
                    ),
                  }}
                >
                  {text.desktop}
                </Trans>
              </div>
            )}
          </div>
        </div>
      </div>
      {modal && (
        <CyberModal
          id={''}
          testId={''}
          isVisible={isOpen}
          onClose={onClose}
          closeIcon="default"
          isLanding={isLanding}
          {...modal}
        />
      )}
    </>
  );
};

export default BannerFlow;
