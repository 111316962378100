import { Icon, IconAlt } from '@BICE_Vida/components';
import classNames from 'classnames';

import styles from './CloseIcon.module.scss';

export type CloseIconPropT = {
  onClick: () => void;
  id?: string;
  testId?: string;
  tooltipText?: string;
  variant?: 'default' | 'white';
  className?: string;
  shapeClassName?: string;
  iconClassName?: string;
};

const CloseIcon = ({
  onClick,
  id,
  testId,
  className,
  shapeClassName,
  iconClassName,
  tooltipText = 'Cerrar',
  variant = 'white',
}: CloseIconPropT) => {
  const containerClassNames = classNames(styles['ttt__close-icon'], className);
  const shapeClassNames = classNames(styles['ttt__close-icon__shape'], shapeClassName, {
    [styles['ttt__close-icon__bg--white']]: variant === 'white',
  });
  const iconClassNames = classNames(styles['modal_icon_'], iconClassName, {
    [styles['ttt__close-icon__icon--default']]: variant === 'default',
    [styles['ttt__close-icon__icon--white']]: variant === 'white',
  });

  return (
    <div id={id} data-testid={testId} className={containerClassNames} onClick={onClick}>
      <IconAlt text={tooltipText} position="bottom-left">
        <span className={shapeClassNames}>
          <Icon name="close" size="medium" className={iconClassNames} />
        </span>
      </IconAlt>
    </div>
  );
};

export default CloseIcon;
