import { FC, useState } from 'react';
import { Trans } from 'react-i18next';
import { BannerT } from '@csi-monorepo/core/domain/insurance';
import useDevice from '@src/hooks/screen/useDevice';
import { cyberBannerViewDetailsBtnEvent } from '@src/utils/dataLayer/landing';
import Image from 'next/image';

import CounterCyber from '../CounterCyber/CounterCyber';
import CyberModal from '../CyberModal';

import BannerLandingMobile from './BannerLandingMobile';

import styles from './BannerLanding.module.scss';

type BannerProps = BannerT & {
  isLanding?: boolean;
  reactRef: React.RefObject<HTMLDivElement>;
  scrollable?: () => void;
};

const Banner: FC<BannerProps> = (props) => {
  const {
    text,
    logo,
    icon,
    isLanding = false,
    modal,
    paddingBottom,
    paddingTop,
    reactRef,
    scrollable,
  } = props;
  const { maxMediaSmall: isMobile } = useDevice();
  const [isOpen, setIsOpen] = useState(false);
  const onClose = () => setIsOpen(false);

  const handleClickInBanner = (event: React.MouseEvent<HTMLDivElement>) => {
    if ((event.target as HTMLDivElement).id === 'detail-button') return;
    if (scrollable) scrollable();
  };

  if (isMobile) return <BannerLandingMobile handleClickInBanner={handleClickInBanner} {...props} />;

  return (
    <>
      <div className={styles.banner} onClick={handleClickInBanner} ref={reactRef}>
        <div className={styles.banner__content} style={{ paddingTop, paddingBottom }}>
          <div className={styles.banner__content__circle}>
            <Image src="/svg/icon/semi-circle.svg" height={120} width={520} alt="" />
          </div>
          {/* Gato */}
          {logo?.src && !isMobile && (
            <div className={styles.banner__content__logo}>
              {logo.tag && (
                <Trans
                  components={{ span: <span className={styles.banner__content__logo__tag} /> }}
                >
                  {logo.tag}
                </Trans>
              )}
              <Image src={logo?.src} alt="" height={logo?.height} width={logo?.width} />
            </div>
          )}
          {/* Cyber icon */}
          {icon && (
            <div className={styles.banner__content__icons}>
              {icon.desktop?.tag && (
                <Trans
                  components={{ span: <span className={styles.banner__content__icons__tag} /> }}
                >
                  {icon.desktop.tag}
                </Trans>
              )}
              <Image
                src={icon.desktop?.src ?? ''}
                width={icon.desktop?.width ?? 267}
                height={icon.desktop?.height ?? 54}
                alt=""
              />
            </div>
          )}
          {/* Textos */}
          <div className={styles.banner__content__texts}>
            {text && (
              <div className={styles.banner__content__texts__promo}>
                <Trans
                  components={{
                    p: <p />,
                    span: <span />,
                    strong: <strong />,
                    a: (
                      <a
                        id="detail-button"
                        onClick={(e) => {
                          e.preventDefault();
                          if (modal) {
                            cyberBannerViewDetailsBtnEvent();
                            setIsOpen(true);
                          }
                        }}
                      />
                    ),
                  }}
                >
                  {text.desktop}
                </Trans>
              </div>
            )}
          </div>
          {/* Contador  */}
          <CounterCyber />
        </div>
      </div>
      {modal && (
        <CyberModal
          id={''}
          testId={''}
          isVisible={isOpen}
          onClose={onClose}
          closeIcon="default"
          isLanding={isLanding}
          {...modal}
        />
      )}
    </>
  );
};

export default Banner;
