import baseDataLayer from './baseDataLayer';

const clickHeaderPageEvent = (text: string) =>
  baseDataLayer({
    event_params: {
      page: '/csi/landing',
      page_title: 'CSI - Landing',
      product: 'CSI',
      action_type: 'click',
      action: `Click botón - ${text}`,
      view_type: 'web',
      product_type: '',
      asegurados: '',
      enfermedad: '',
      prima: '',
      quantity: '',
      venta_asistida: '',
      rut_agente: '',
    },
    user: {
      user: { userId: '' },
      userEmail: '',
    },
  });

const ClickVirtualBranchHeaderPageEvent = () =>
  baseDataLayer({
    event_params: {
      page: '/csi/landing',
      page_title: 'CSI - Landing',
      product: 'CSI',
      action_type: 'click',
      action: 'Click botón - Ir a sucursal virtual',
      view_type: 'web',
      product_type: '',
      asegurados: '',
      enfermedad: '',
      prima: '',
      quantity: '',
      venta_asistida: '',
      rut_agente: '',
    },
    user: {
      user: { userId: '' },
      userEmail: '',
    },
  });

export { clickHeaderPageEvent as ClickHeaderPageEvent, ClickVirtualBranchHeaderPageEvent };
