const MILLISECONDS_OF_A_SECOND = 1000;
const MILLISECONDS_OF_A_MINUTE = MILLISECONDS_OF_A_SECOND * 60;
const MILLISECONDS_OF_A_HOUR = MILLISECONDS_OF_A_MINUTE * 60;
const MILLISECONDS_OF_A_DAY = MILLISECONDS_OF_A_HOUR * 24;

export const updateCountdown = (
  callback: (days: string, hours: string, minutes: string, seconds: string) => void,
  endDate: Date
) => {
  const NOW = new Date();
  const DURATION = endDate.getTime() - NOW.getTime();
  const REMAINING_DAYS = Math.max(Math.floor(DURATION / MILLISECONDS_OF_A_DAY), 0)
    .toString()
    .padStart(2, '0');
  const REMAINING_HOURS = Math.max(
    Math.floor((DURATION % MILLISECONDS_OF_A_DAY) / MILLISECONDS_OF_A_HOUR),
    0
  )
    .toString()
    .padStart(2, '0');
  const REMAINING_MINUTES = Math.max(
    Math.floor((DURATION % MILLISECONDS_OF_A_HOUR) / MILLISECONDS_OF_A_MINUTE),
    0
  )
    .toString()
    .padStart(2, '0');
  const REMAINING_SECONDS = Math.max(
    Math.floor((DURATION % MILLISECONDS_OF_A_MINUTE) / MILLISECONDS_OF_A_SECOND),
    0
  )
    .toString()
    .padStart(2, '0');

  callback(REMAINING_DAYS, REMAINING_HOURS, REMAINING_MINUTES, REMAINING_SECONDS);
  return { REMAINING_DAYS, REMAINING_HOURS, REMAINING_MINUTES, REMAINING_SECONDS };
};
