import { StepFooterPropsI } from '@BICE_Vida/tsp-components';
import { CHECKOUT, HEALTHDECLARATION, SUCCESSPAYMENT } from '@constants/urls';
import { CheckoutStepPropsT, StepsPropsT, StepT } from '@src/types/steps';
import i18next from 'i18next';

const clientSteps: Record<string, StepT> = {
  /** Health-declaration */
  [HEALTHDECLARATION]: {
    continueUrl: CHECKOUT,
    backUrl: '',
    stepFooterProps({
      isDisabled,
      isLoading,
      continueOnClick,
      backOnClick,
    }: StepsPropsT): StepFooterPropsI {
      return {
        flow: {
          currentStep: 1,
          wasIncreased: true,
          steps: 3,
          missingStepsText: i18next.t('STEP_FOOTER_MISSING_STEPS', {
            count: 2,
          }),
        },
        continueBtn: {
          isDisabled,
          isLoading,
          text: i18next.t('STEP_FOOTER_TEXT_BUTTON_CONTINUE'),
          onClick: () => {
            if (continueOnClick) continueOnClick(this.continueUrl);
          },
        },
        backBtn: {
          text: i18next.t('STEP_FOOTER_TEXT_BUTTON_BACK'),
          onClick: () => {
            if (backOnClick) backOnClick(this.backUrl);
          },
        },
      };
    },
  },
  /** Checkout */
  [CHECKOUT]: {
    continueUrl: SUCCESSPAYMENT,
    backUrl: HEALTHDECLARATION,
    stepFooterProps({
      isDisabled,
      isLoading,
      price,
      continueOnClick,
      backOnClick,
      skippedSteps,
    }: CheckoutStepPropsT): StepFooterPropsI {
      return {
        flow: {
          currentStep: 2,
          wasIncreased: true,
          steps: 3,
          missingStepsText: i18next.t('STEP_FOOTER_MISSING_STEPS', {
            count: 1,
          }),
        },
        continueBtn: {
          isDisabled,
          isLoading,
          text: i18next.t('STEP_FOOTER_CHECKOUT_PAY_BUTTON', { PRICE: price }),
          onClick: () => {
            if (continueOnClick) continueOnClick(this.continueUrl);
          },
        },
        backBtn: {
          text: i18next.t('STEP_FOOTER_TEXT_BUTTON_BACK'),
          onClick: () => {
            if (backOnClick) backOnClick(this.backUrl);
          },
        },
      };
    },
  },
  /** pago-exito-error */
  [SUCCESSPAYMENT]: {
    continueUrl: '',
    backUrl: CHECKOUT,
    last: true,
    stepFooterProps({
      isDisabled,
      isLoading,
      price,
      continueOnClick,
      backOnClick,
    }: CheckoutStepPropsT): StepFooterPropsI {
      return {
        flow: {
          currentStep: 3,
          wasIncreased: true,
          steps: 3,
          missingStepsText: i18next.t('STEP_FOOTER_MISSING_STEPS', {
            count: 0,
          }),
        },
        continueBtn: {
          isDisabled,
          isLoading,
          text: i18next.t('STEP_FOOTER_CHECKOUT_PAY_BUTTON', { PRICE: price }),
          onClick: () => {
            if (continueOnClick) continueOnClick(this.continueUrl);
          },
        },
        backBtn: {
          text: i18next.t('STEP_FOOTER_TEXT_BUTTON_BACK'),
          onClick: () => {
            if (backOnClick) backOnClick(this.backUrl);
          },
        },
      };
    },
  },
};

export default clientSteps;
