import { ReactNode, useEffect, useState } from 'react';
import ReactModal from 'react-modal';
import { MODAL_ANIMATION_TIME } from '@src/constants/modal';
import classNames from 'classnames';

import CloseIcon from './CloseIcon';

import styles from './Modal.module.scss';

export type BaseModalPropT = {
  isVisible: boolean;
  onClose: () => void;
  closeIcon: 'default' | 'white';
  id?: string;
  testId?: string;
  closeOnIcon?: boolean;
  fullscreen?: boolean;
  closeOnOverlay?: boolean;
  className?: string;
  overlayClassName?: string;
  bodyClassName?: string;
  contentClassName?: string;
  iconClassName?: string;
  children?: ReactNode;
  modalWidth?: number;
};

const BaseModal = ({
  id,
  testId,
  isVisible,
  onClose,
  closeIcon = 'default',
  closeOnIcon = true,
  closeOnOverlay = false,
  fullscreen = false,
  className,
  overlayClassName,
  bodyClassName,
  contentClassName,
  iconClassName,
  children,
  modalWidth,
}: BaseModalPropT) => {
  const [showOverlay, setShowOverlay] = useState(false);

  const modalClassNames = classNames(styles['ttt__modal'], className, {
    [styles['ttt__modal__fullscreen']]: fullscreen,
    [styles['ttt__modal__close-icon']]: closeOnIcon,
  });

  const containerClassNames = classNames({
    [styles['ttt__modal__container']]: fullscreen,
  });

  const bodyClassNames = classNames(bodyClassName);
  const contentClassNames = classNames(contentClassName);
  const overlayClassNames = classNames(styles['ttt__modal--overlay'], overlayClassName, {
    [styles['ttt__modal--overlay__hide']]: !showOverlay,
    [styles['ttt__modal--overlay__show']]: showOverlay,
    [styles['ttt__modal__fullscreen']]: fullscreen,
  });

  const onRequestClose = closeOnOverlay && Boolean(onClose) ? onClose : undefined;

  useEffect(() => {
    let timeOut: NodeJS.Timeout;

    if (isVisible) {
      timeOut = setTimeout(() => {
        setShowOverlay(true);
      }, 50);
      document.documentElement.style.overflow = 'hidden';
    }

    return () => {
      clearTimeout(timeOut);
      document.documentElement.style.overflow = 'auto';
      setShowOverlay(false);
    };
  }, [isVisible]);

  return (
    <ReactModal
      isOpen={isVisible}
      closeTimeoutMS={MODAL_ANIMATION_TIME}
      ariaHideApp={false}
      shouldCloseOnEsc={false}
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={closeOnOverlay}
      className={modalClassNames}
      overlayClassName={overlayClassNames}
      style={{
        content: {
          width: modalWidth,
        },
      }}
    >
      <div id={id} data-testid={testId} className={containerClassNames}>
        <div className={bodyClassNames}>
          {closeOnIcon && Boolean(onClose) && (
            <CloseIcon iconClassName={iconClassName} variant={closeIcon} onClick={onClose} />
          )}
        </div>
        <div className={contentClassNames}>
          <div>{children}</div>
        </div>
      </div>
    </ReactModal>
  );
};

export default BaseModal;
