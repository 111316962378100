import { LandingPlansFeaturesEnum } from '@src/types/landing';

import { getAidedAttribution } from '../aidedAtribution';
import { capitalize } from '../textUtils';

import baseDataLayer from './baseDataLayer';

const trackViewPLandingPageEvent = (attribution = ''): void =>
  baseDataLayer({
    event_params: {
      page: '/csi/landing',
      page_title: 'CSI - Landing',
      product: 'CSI',
      action_type: 'Pageview',
      action: 'Paso 0 - Pageview CSI Landing',
      view_type: 'web',
      product_type: '',
      asegurados: '',
      enfermedad: '',
      prima: '',
      venta_asistida: '',
      rut_agente: '',
      attribution,
    },
    user: { userId: '' },
    userEmail: '',
  });

const hiringBtnEvent = (): void =>
  baseDataLayer({
    event_params: {
      page: '/csi/landing',
      page_title: 'CSI - Landing',
      product: 'CSI',
      action_type: 'click',
      action: 'Click botón Cotiza ahora',
      view_type: 'web',
      product_type: '',
      asegurados: '',
      enfermedad: '',
      prima: '',
      venta_asistida: '',
      rut_agente: '',
    },
    user: { userId: '' },
    userEmail: '',
  });

const hiringMenuBtnEvent = (): void =>
  baseDataLayer({
    event_params: {
      page: '/csi/landing',
      page_title: 'CSI - Landing',
      product: 'CSI',
      action_type: 'click',
      action: 'Click botón menú Cotiza ahora ',
      view_type: 'web',
      product_type: '',
      asegurados: '',
      enfermedad: '',
      prima: '',
      venta_asistida: '',
      rut_agente: '',
    },
    user: { userId: '' },
    userEmail: '',
  });

const choosePlanBtnEvent = (planName: string, quantity: number, price: number): void =>
  baseDataLayer({
    event_params: {
      page: '/csi/landing',
      page_title: 'CSI - Landing',
      product: 'CSI',
      action_type: 'click',
      action: 'Click botón Contrata este plan',
      view_type: 'web',
      product_type: planName,
      asegurados: quantity,
      enfermedad: '',
      prima: price,
      venta_asistida: '',
      rut_agente: '',
    },
    user: { userId: '' },
    userEmail: '',
  });

const choosePlanVersusBtnEvent = (planName: string, quantity: number, price: number): void =>
  baseDataLayer({
    event_params: {
      page: '/csi/landing',
      page_title: 'CSI - Landing',
      product: 'CSI',
      action_type: 'click',
      action: `Click botón Contrata este plan - Plan ${capitalize(planName)} Comparador`,
      view_type: 'web',
      product_type: planName,
      asegurados: quantity,
      enfermedad: '',
      prima: price,
      venta_asistida: '',
      rut_agente: '',
    },
    user: { userId: '' },
    userEmail: '',
  });

const chooseFullPlanModalBtnEvent = (planName: string, quantity: number, price: number): void =>
  baseDataLayer({
    event_params: {
      page: '/csi/landing',
      page_title: 'CSI - Landing',
      product: 'CSI',
      action_type: 'click',
      action: 'Click botón Contrata este plan modal',
      view_type: 'web',
      product_type: planName,
      asegurados: quantity,
      enfermedad: '',
      prima: price,
      venta_asistida: '',
      rut_agente: '',
    },
    user: { userId: '' },
    userEmail: '',
  });

const planMoreDetailsModalEvent = (
  planName: string,
  quantity: number,
  price: number | undefined
): void =>
  baseDataLayer({
    event_params: {
      page: '/csi/landing',
      page_title: 'CSI - Landing',
      product: 'CSI',
      action_type: 'click',
      action: 'Click link Ver mas detalles',
      view_type: 'web',
      product_type: planName,
      asegurados: quantity,
      enfermedad: '',
      prima: price,
      venta_asistida: '',
      rut_agente: '',
    },
    user: { userId: '' },
    userEmail: '',
  });

const downloadDocumentlEvent = (name: string): void =>
  baseDataLayer({
    event_params: {
      page: '/csi/landing',
      page_title: 'CSI - Landing',
      product: 'CSI',
      action_type: 'click',
      action: `Click descargar ${name}`,
      product_type: '',
      asegurados: '',
      enfermedad: '',
      prima: '',
      venta_asistida: '',
      rut_agente: '',
    },
    user: { userId: '' },
    userEmail: '',
  });
const FaqQuestionsEvent = (question: number): void =>
  baseDataLayer({
    event_params: {
      page: '/csi/landing',
      page_title: 'CSI - Landing',
      product: 'CSI',
      action_type: 'click',
      action: `Click en pregunta:${question}`,
      view_type: 'web',
      product_type: '',
      asegurados: '',
      enfermedad: '',
      prima: '',
      venta_asistida: '',
      rut_agente: '',
    },
    user: { userId: '' },
    userEmail: '',
  });

const openCoveragesLandingEvent = ({
  isAided,
  userId,
  planName,
  sponsor,
  preQuotation,
}: {
  isAided: boolean;
  userId: string;
  planName: string;
  sponsor: string | undefined;
  preQuotation: boolean | undefined;
}) =>
  baseDataLayer({
    event_params: {
      page: '/csi/cobertura',
      page_title: 'CSI - Cobertura',
      product: 'CSI',
      action_type: 'Pageview',
      action: 'Pantalla Cobertura',
      view_type: 'web',
      product_type: planName,
      asegurados: '',
      enfermedad: '',
      prima: '',
      venta_asistida: getAidedAttribution(isAided, sponsor, preQuotation),
      rut_agente: '',
    },
    user: {
      userId,
    },
  });

const coverageModalLandingEvent = ({
  isAided,
  userId,
  planName,
  sponsor,
  preQuotation,
}: {
  isAided: boolean;
  userId: string;
  planName?: string;
  sponsor?: string;
  preQuotation?: boolean;
}) =>
  baseDataLayer({
    event_params: {
      page: '/csi/cobertura',
      page_title: 'CSI - Cobertura',
      product: 'CSI',
      action_type: 'Pageview',
      action: 'Pantalla Cobertura',
      view_type: 'web',
      product_type: planName,
      asegurados: '',
      enfermedad: '',
      prima: '',
      venta_asistida: getAidedAttribution(isAided, sponsor, preQuotation),
      rut_agente: '',
    },
    user: {
      userId,
    },
  });

const changeTabCoverageModalEvent = ({
  isAided,
  userId,
  planName,
  sponsor,
  preQuotation,
}: {
  isAided: boolean;
  userId: string;
  planName?: string;
  sponsor?: string;
  preQuotation?: boolean;
}) =>
  baseDataLayer({
    event_params: {
      page: '/csi/cobertura',
      page_title: 'CSI - Cobertura',
      product: 'CSI',
      action_type: 'click',
      action: `Click botón Plan ${planName} - Cobertura`,
      view_type: 'web',
      product_type: planName,
      asegurados: '',
      enfermedad: '',
      prima: '',
      venta_asistida: getAidedAttribution(isAided, sponsor, preQuotation),
      rut_agente: '',
    },
    user: {
      userId,
    },
  });

const changeTabFeaturesSectionEvent = ({
  isAided,
  userId,
  tab,
  planName,
  sponsor,
  preQuotation,
}: {
  isAided: boolean;
  userId: string;
  tab: LandingPlansFeaturesEnum;
  planName?: string;
  sponsor?: string;
  preQuotation?: boolean;
}) =>
  baseDataLayer({
    event_params: {
      page: '/csi/landing',
      page_title: 'CSI - Landing',
      product: 'CSI',
      action_type: 'click',
      action: `Click botón ${tab} - body`,
      view_type: 'web',
      product_type: planName,
      asegurados: '',
      enfermedad: '',
      prima: '',
      venta_asistida: getAidedAttribution(isAided, sponsor, preQuotation),
      rut_agente: '',
    },
    user: {
      userId,
    },
  });

const clickViewDetailsFeaturesSectionEvent = ({
  isAided,
  userId,
  planName,
  sponsor,
  preQuotation,
}: {
  isAided: boolean;
  userId: string;
  planName?: string;
  sponsor?: string;
  preQuotation?: boolean;
}) =>
  baseDataLayer({
    event_params: {
      page: '/csi/landing',
      page_title: 'CSI - Landing',
      product: 'CSI',
      action_type: 'click',
      action: `Click link Conoce los detalles - Características`,
      view_type: 'web',
      product_type: planName,
      asegurados: '',
      enfermedad: '',
      prima: '',
      venta_asistida: getAidedAttribution(isAided, sponsor, preQuotation),
      rut_agente: '',
    },
    user: {
      userId,
    },
  });
const clickViewDetailsWhatCoverSectionEvent = ({
  isAided,
  userId,
  planName,
  sponsor,
  preQuotation,
}: {
  isAided: boolean;
  userId: string;
  planName?: string;
  sponsor?: string;
  preQuotation?: boolean;
}) =>
  baseDataLayer({
    event_params: {
      page: '/csi/landing',
      page_title: 'CSI - Landing',
      product: 'CSI',
      action_type: 'click',
      action: `Click link Revisando más detalles - Qué cubre y qué no`,
      view_type: 'web',
      product_type: planName,
      asegurados: '',
      enfermedad: '',
      prima: '',
      venta_asistida: getAidedAttribution(isAided, sponsor, preQuotation),
      rut_agente: '',
    },
    user: {
      userId,
    },
  });
const clickViewDetailsWhatNotCoverSectionEvent = ({
  isAided,
  userId,
  planName,
}: {
  isAided: boolean;
  userId: string;
  planName?: string;
}) =>
  baseDataLayer({
    event_params: {
      page: '/csi/landing',
      page_title: 'CSI - Landing',
      product: 'CSI',
      action_type: 'click',
      action: `Click link mas detalle que no cubre - Qué cubre y qué no`,
      view_type: 'web',
      product_type: planName,
      asegurados: '',
      enfermedad: '',
      prima: '',
      venta_asistida: isAided ? 'si' : 'no',
      rut_agente: '',
    },
    user: {
      userId,
    },
  });

const quotationPlanFromModalEvent = ({
  isAided,
  userId,
  planName,
}: {
  isAided: boolean;
  userId: string;
  planName: string;
}) =>
  baseDataLayer({
    event_params: {
      page: '/csi/cobertura',
      page_title: 'CSI - Cobertura',
      product: 'CSI',
      action_type: 'click',
      action: `Click Cotiza este plan ${planName} - Cobertura`,
      view_type: 'web',
      product_type: planName,
      asegurados: '',
      enfermedad: '',
      prima: '',
      venta_asistida: isAided ? 'si' : 'no',
      rut_agente: '',
    },
    user: {
      userId,
    },
  });

const quotationFlowFromLandingCardsEvent = () =>
  baseDataLayer({
    event_params: {
      page: '/csi/landing',
      page_title: 'CSI - Landing',
      product: 'CSI',
      action_type: 'click',
      action: 'Click botón Contrata ahora en las cards',
      view_type: 'web',
      product_type: '',
      asegurados: '',
      enfermedad: '',
      prima: '',
      venta_asistida: '',
      rut_agente: '',
    },
    user: { userId: '' },
    userEmail: '',
  });

const changeToExtraPlanBtnEvent = (planName: string, price: number, extraPlan: boolean): void =>
  baseDataLayer({
    event_params: {
      page: '/csi/landing',
      page_title: 'CSI - Landing',
      product: 'CSI',
      action_type: 'click',
      action: `Click botón Cambiar a ${extraPlan ? 'plan hospitalario' : 'planes base'}`,
      view_type: 'web',
      product_type: planName,
      asegurados: '',
      enfermedad: '',
      prima: price,
      venta_asistida: '',
      rut_agente: '',
    },
    user: { userId: '' },
    userEmail: '',
  });

const cyberBannerViewDetailsBtnEvent = (): void =>
  baseDataLayer({
    event_params: {
      page: '/csi/landing',
      page_title: 'CSI - Landing',
      product: 'CSI',
      action_type: 'click',
      action: 'Click botón - ver detalles banner de cyber',
      view_type: 'web',
      product_type: '',
      asegurados: '',
      enfermedad: '',
      prima: '',
      venta_asistida: '',
      rut_agente: '',
    },
    user: { userId: '' },
    userEmail: '',
  });

const cyberMidBannerViewDetailsBtnEvent = (): void =>
  baseDataLayer({
    event_params: {
      page: '/csi/landing',
      page_title: 'CSI - Landing',
      product: 'CSI',
      action_type: 'click',
      action: 'Click botón - ver detalles mid banner de cyber',
      view_type: 'web',
      product_type: '',
      asegurados: '',
      enfermedad: '',
      prima: '',
      venta_asistida: '',
      rut_agente: '',
    },
    user: { userId: '' },
    userEmail: '',
  });

export {
  changeTabCoverageModalEvent,
  changeTabFeaturesSectionEvent,
  changeToExtraPlanBtnEvent,
  chooseFullPlanModalBtnEvent,
  choosePlanBtnEvent,
  choosePlanVersusBtnEvent,
  clickViewDetailsFeaturesSectionEvent,
  clickViewDetailsWhatCoverSectionEvent,
  clickViewDetailsWhatNotCoverSectionEvent,
  coverageModalLandingEvent,
  cyberBannerViewDetailsBtnEvent,
  cyberMidBannerViewDetailsBtnEvent,
  downloadDocumentlEvent,
  FaqQuestionsEvent,
  hiringBtnEvent,
  hiringMenuBtnEvent,
  openCoveragesLandingEvent,
  planMoreDetailsModalEvent,
  quotationFlowFromLandingCardsEvent,
  quotationPlanFromModalEvent,
  trackViewPLandingPageEvent,
};
